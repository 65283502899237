
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { handleAnonymizeName } from '@/composables/DataAnonymization';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { IFractionData } from './ListPage.vue';
import { IFractionResponse } from './PostPage.vue';

function checkEmptyFields(obj: IFractionData): boolean {
  for (let key in obj) {
    if (obj[key] === "") {
      return true;
    }
  }
  return false;
}

export default defineComponent({
  name: "StoreProfilePut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    const route = useRoute();
    const loaderStore = useLoaderStore();
    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data: null | IFractionData
    }>({
      isLoading: false,
      noError: false,
      data: null
    })
    const { id } = route.params;
    const { showTimeAlert } = useAlert()
    const isRequiredField = ref(false)

    // Functions
    async function onSubmitForm() {
      isRequiredField.value = false
      const { data } = queryObject.value
      if(!data)
        return
      if(checkEmptyFields(data))
        return isRequiredField.value = true
      loaderStore.open()
      try {
        const { data } = queryObject.value
        if(data) {
          const { sku, unit_sku, value, measure } = data
          const result: IFractionResponse = await axios.put(`/api/putFraction`, { id, sku, unit_sku, value, measure })
          showTimeAlert("Atualizado com sucesso!")
          return router.push("/produtos-fracionados")
        }
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
        loaderStore.close()
      }
    }

    // Lifecycles
    onMounted(async () => {
      loaderStore.open()
      try {
        const result: IFractionResponse = await axios.get(`/api/getFractionView/${id}`)
        const { data } = result
          return queryObject.value.data = data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    })

    return {
      queryObject,
      isRequiredField,
      onSubmitForm,
      handleAnonymizeName
    }
  }
})
